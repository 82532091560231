"use client";

import { useTranslations } from "next-intl";
import Image from "next/image";
import { useEffect, useState } from "react";

const values = new Array(3).fill('')

export const About = ({ site_title }) => {
  const t = useTranslations();
  const [team, setTeam] = useState([]);

  useEffect(() => {
    fetch(`${process.env.API_FOR_LOGIN}/api/team`)
      .then(res => res.json())
      .then(res => setTeam(res?.body ?? []))
  }, [])

  return (
    <div className="bg-white">
      {/* Header */}

      <main className="isolate">
        {/* Hero section */}
        <div className="relative isolate -z-10">
          <svg
            className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
            />
          </svg>
          <div
            className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
            aria-hidden="true"
          >
            <div
              className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  "polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)",
              }}
            />
          </div>
          <div className="overflow-hidden">
            <div className="mx-auto max-w-7xl px-6 md:pb-32 pb-12  sm:pt-6 lg:px-8 lg:pt-32">
              <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-start">
                <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                  <h1 className="lg:text-6xl md:text-4xl text-2xl font-bold tracking-tight text-gray-900">
                    {t("aboutHeader", { name: site_title })}
                  </h1>
                  <p className="relative mt-6 md:text-lg text-base leading-8 text-gray-600 sm:max-w-xs lg:max-w-none">
                    {t("aboutDesc1", { name: site_title })}
                  </p>
                  <p className="relative mt-6 md:text-lg text-base leading-8 text-gray-600 sm:max-w-xs lg:max-w-none">{t("aboutDesc2")}
                  </p>
                  <p className="relative mt-6 md:text-lg text-base leading-8 text-gray-600 sm:max-w-xs lg:max-w-none">{t("aboutDesc3", { name: site_title })}</p>
                  <p className="relative mt-6 md:text-lg text-base leading-8 text-gray-600 sm:max-w-xs lg:max-w-none">{t("aboutDesc4", { name: site_title })}</p>
                  <p className="relative mt-6 md:text-lg text-base leading-8 text-gray-600 sm:max-w-xs lg:max-w-none">{t("aboutDesc5", { name: site_title })}</p>
                </div>
                <div className=" flex gap-8 lg:mt-0  md:-mt-44 sm:-mt-48 -mt-40 sm:justify-start pl-0 lg:pl-0 scale-50 sm:scale-75 md:scale-100">
                  <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0  lg:order-last  xl:order-none xl:pt-80 lg:pt-36 sm:pt-80">
                    <div className="relative">
                      <Image
                        src="https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
                        alt=""
                        width={500}
                        height={500}
                        unoptimized
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                    <div className="relative">
                      <Image
                        src="https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
                        alt=""
                        width={100}
                        unoptimized
                        height={100}
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <Image
                        src="https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=396&h=528&q=80"
                        alt=""
                        unoptimized
                        width={100}
                        height={100}
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                    <div className="relative">
                      <Image
                        src="https://images.unsplash.com/photo-1670272504528-790c24957dda?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=left&w=400&h=528&q=80"
                        alt=""
                        width={100}
                        height={100}
                        unoptimized
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <Image
                        src="https://images.unsplash.com/photo-1670272505284-8faba1c31f7d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
                        alt=""
                        width={100}
                        unoptimized
                        height={100}
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Content section */}
        <div className="mx-auto -mt-12 max-w-7xl px-6 sm:mt-0 lg:px-8 xl:-mt-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <h2 className="md:text-3xl text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {t("ourMission")}
            </h2>
            <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
              <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
                <p className="md:text-lg text-base leading-8 text-gray-600">
                  {t("missionDesc")}
                </p>
              </div>
              {/* <div className="lg:flex lg:flex-auto lg:justify-center">
                <dl className="w-64 space-y-8 xl:w-80">
                  {stats.map((stat) => (
                    <div
                      key={stat.label}
                      className="flex flex-col-reverse gap-y-4"
                    >
                      <dt className="text-base leading-7 text-gray-600">
                        {stat.label}
                      </dt>
                      <dd className="text-5xl font-semibold tracking-tight text-gray-900">
                        {stat.value}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div> */}
            </div>
          </div>
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none mt-10">
            <h2 className="md:text-3xl text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {t("ourVision")}
            </h2>
            <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
              <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
                <p className="md:text-lg text-base leading-8 text-gray-600">
                  {t("visionDesc")}
                </p>
              </div>
              {/* <div className="lg:flex lg:flex-auto lg:justify-center">
                <dl className="w-64 space-y-8 xl:w-80">
                  {stats.map((stat) => (
                    <div
                      key={stat.label}
                      className="flex flex-col-reverse gap-y-4"
                    >
                      <dt className="text-base leading-7 text-gray-600">
                        {stat.label}
                      </dt>
                      <dd className="text-5xl font-semibold tracking-tight text-gray-900">
                        {stat.value}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div> */}
            </div>
          </div>
        </div>

        {/* Image section */}
        <div className="mt-32 sm:mt-40 xl:mx-auto xl:max-w-7xl xl:px-8">
          <Image
            src="/static/about.webp"
            alt=""
            unoptimized
            width={700}
            height={300}
            className="aspect-[5/2] w-full object-cover xl:rounded-3xl"
          />
        </div>

        {/* Values section */}
        <div className="mx-auto mt-16 xmd:mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="md:text-3xl text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {t("ourValues")}
            </h2>
          </div>
          <dl className="mx-auto mt-10 xmd:mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 md:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {values.map((_, i) => (
              <div key={i}>
                <dt className="font-semibold text-gray-900">{t(`value${i + 1}-title`)}</dt>
                <dd className="mt-1 text-gray-600">{t(`value${i + 1}-subtitle`)}</dd>
              </div>
            ))}
          </dl>
        </div>

        {/* Logo cloud */}

        {/* Team section */}
        <div className="mx-auto mt-10 max-w-7xl px-1 sm:mt-20 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 mb-7.5">
            <h2 className="md:text-3xl text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {t("ourTeam")}
            </h2>
            <p className="mt-6 md:text-lg text-base leading-8 text-gray-600">
              {t("teamMeet")}
            </p>
          </div>
          <ul
            role="list"
            className="grid place-items-center grid-cols-2 md:grid-cols-3 gap-6 sm:gap-[40px] md:gap-[79px] lg:grid-cols-5"
          >
            {team.map(({ id, full_name, job_title, image }) => (
              <li
                key={id}
                className="flex flex-col w-[90px] xmd:w-[196px] xmd:min-h-[230px] gap-2.5 xmd:gap-6 justify-center items-center"
              >
                <div className="relative w-full">
                  {image ? (
                    <Image
                      src={process.env.API_FOR_LOGIN + "/storage/" + image}
                      alt={id}
                      width={196}
                      height={230}
                      className="xmd:w-[196px] w-full relative bottom-0 z-50 mx-auto h-[90px] xmd:h-[230px] rounded-full object-cover"
                    />
                  ) : (
                    ""
                  )}
                  {/* <div className="absolute bottom-0 left-0 z-40 w-[196px] h-[196px] rounded-full bg-blue-50"></div> */}
                </div>
                <div className="flex flex-col justify-center items-center gap-1">
                  <span className="text-slate-700 text-sm xmd:text-xl font-medium whitespace-nowrap ">
                    {full_name}
                  </span>
                  <span className="text-slate-500 text-sm text-center whitespace-nowrap ">
                    {job_title}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </main>

      {/* Footer */}
    </div>
  );
};
